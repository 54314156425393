import {AppRoutingModule} from '../app-routing.module';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { ApplicationsHomeComponent } from './applications/applications-home/applications-home.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { ApplicationDetailsComponent } from './applications/application-details/application-details.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ApplicationCardComponent} from './applications/applications-home/application-card/application-card.component';
import {RouterModule} from '@angular/router';
import {ModalModule} from './_modal';
import { MyApplicationsComponent } from './common/navbar/my-applications/my-applications.component';
import { ProfileComponent } from './common/navbar/profile/profile.component';
import { StagesSvgComponent } from './common/navbar/my-applications/stages-svg/stages-svg.component';
import { ProfileEditComponent } from './common/navbar/profile/profile-edit/profile-edit.component';
// import { InputUploadComponent } from './questions/load-questions/components/input-upload/input-upload.component';
// import { InputMultiselectComponent } from './questions/load-questions/components/input-multiselect/input-multiselect.component';
// import { InputSelectComponent } from './questions/load-questions/components/input-select/input-select.component';
import { InputTextComponent } from './questions/load-questions/components/input-text/input-text.component';
import { InputStatementComponent } from './questions/load-questions/components/input-statement/input-statement.component';
import {LoadQuestionsComponent} from './questions/load-questions/load-questions.component';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {AngularFileUploaderModule} from 'angular-file-uploader';
import {OnBoardingQuestionsComponent} from './onBoarding/on-boarding-questions/on-boarding-questions.component';
import {RegisterSuccessComponent} from './accounts/register-success/register-success.component';
import {VerifyUserComponent} from './accounts/verify-user/verify-user.component';
import {ErrorComponent} from './accounts/error/error.component';
import {ChangePasswordComponent} from './accounts/change-password/change-password.component';
import {InputFreeTextComponent} from './questions/render-stages-and-questions/components/input-free-text/input-free-text.component';
import {InputMultiSelectComponent} from './questions/render-stages-and-questions/components/input-multi-select/input-multi-select.component';
import {RenderStagesAndQuestionsComponent} from './questions/render-stages-and-questions/render-stages-and-questions.component';
import {InputSelectComponent} from './questions/render-stages-and-questions/components/input-select/input-select.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {InputUploadComponent} from './questions/render-stages-and-questions/components/input-upload/input-upload.component';

export const components = [
  ApplicationsHomeComponent,
  NavbarComponent,
  ApplicationDetailsComponent,
  ApplicationCardComponent,
  MyApplicationsComponent,
  ProfileComponent,
  StagesSvgComponent,
  ProfileEditComponent,
  LoadQuestionsComponent,
  InputTextComponent,
  InputStatementComponent,
  OnBoardingQuestionsComponent,
  RegisterSuccessComponent,
  VerifyUserComponent,
  ErrorComponent,
  ChangePasswordComponent,
  InputFreeTextComponent,
  InputUploadComponent,
  InputSelectComponent,
  InputMultiSelectComponent,
  RenderStagesAndQuestionsComponent
];

@NgModule({
  declarations: components,
  imports: [
    AppRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    ModalModule,
    FormsModule,
    AngularMultiSelectModule,
    AngularFileUploaderModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [components],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class ComponentsModule {
  constructor() {
  }
}
