<div class="container background">
  <div class="left-box">
    <div class="company-logo">
      <a>
        <img  class="logo" src="../../../../assets/logos/Hazie-logo-white.png" alt="Card image cap">
      </a>
    </div>
  </div>

  <div class="right-box">
    <div class="card">
      <div class="card-header">
        <div class="company-logo">
          <a routerLink="/login">
            <img  class="logo" src="../../../../assets/logos/Hazie-logo.png" alt="Card image cap">
          </a>
        </div>

        <div class="header-text">
          <h1>FORGOT PASSWORD</h1>
          <small>ENTER THE EMAIL YOU USED TO REGISTER</small>
        </div>
      </div>

      <div class="card-body">
        <div class="form-container">
          <form  [formGroup]="forgotPasswordForm" (ngSubmit)="sendRecoveryEmail()">
            <input
              type="email"
              formControlName="email"
              placeholder="EMAIL ADDRESS"/>

            <input type="submit" value="CHANGE PASSWORD">
          </form>
        </div>
      </div>

      <div class="card-footer">
        <div class="errors">
          <div class="error-messages" *ngIf="email.touched">
            <div *ngIf="email.invalid && email.dirty">* Email needs to be something like me@example.com </div>
            <div *ngIf="!email.value">* Email can't be blank</div>
            <div *ngIf="error">* Please use the email you used to register with</div>
          </div>
        </div>

        <div class="forgot-password">
          <div class="sign-up-login">
            Have an account? <a routerLink="/login" class="color-alert">Sign In</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

