import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserRegistrationModel} from '../../../Core/Models/UserRegistration.Model';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {CandidateModel, LocationModel} from '../../../Core/Models/CandidateModelModell.Model';
import {CandidatesService} from '../../../Services/CandidatesService/candidates.service';
import {CandidatesprofileService} from '../../../Services/ProfilesService/CandidateService/candidatesprofile.service';
import {CandidateProfileModel} from '../../../Core/Models/CandidateProfile.Model';
import {LanguageEnum} from '../../../Core/Enums/Language.Enum';
import {DisabilityEnum} from '../../../Core/Enums/Disability.Enum';
import {PopulationGroupEnum} from '../../../Core/Enums/PopulationGroup.Enum';
import {Gender} from '../../../Core/Models/Gender.Model';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    disablePassword: boolean;
    enableAD: boolean;
    registerForm: FormGroup;
    useAD: boolean;
    errorMessages: string[];
    success = false;
    public applicationId;
    user: UserRegistrationModel;
    candidateModel: CandidateModel;
    candidateProfileModel: CandidateProfileModel;
    submitted = false;
    title = 'Hazie - Registration';

    constructor(private authenticationService: AuthenticationService,
                private router: Router, private candidatesService: CandidatesService,
                private candidatesprofileService: CandidatesprofileService,
                private route: ActivatedRoute,
                private formBuilder: FormBuilder,
                private titleService: Title) {

        this.applicationId = this.route.snapshot.queryParams.JobId;

        this.disablePassword = this.authenticationService.forceADUserRegistration;

        this.useAD = this.authenticationService.allowADUserRegistration;

    }

    ngOnInit(): void {
      this.initiateForm();
      this.titleService.setTitle(this.title);
    }

    initiateForm(): void {
        this.registerForm = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.minLength(3)]],
            lastName: ['', [Validators.required, Validators.minLength(3)]],
            email: ['',
                [Validators.required,
                    Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]],
            idNumber: ['',
                    [Validators.required,
                    Validators.pattern(/^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/)]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            // username: this.username,
            // confirmPassword: this.confirmPassword,
        });
    }

    returnGenderFromIdNumber(idNumber: string): Gender {
      if (idNumber) {
        const gender = +idNumber.slice(6, -3);
        if (gender >= 5000 && gender <= 9999) {
          return Gender.Male;
        } else if (gender >= 0 && gender <= 4999) {
          return Gender.Female;
        } else {
          return Gender.Undisclosed;
        }
      }
    }

    returnCandidateDateOfBirth(idNumber: string): Date {
      if (idNumber) {
        const year = +(idNumber[0] + idNumber[1]);
        let date = '';
        /* This section will be obselete with ID Numbers from 2040 onwards */
        if (year >= 0 && year <= 40) { /* this only considers 2000 to 2040 */
          date += '20' + year.toString() + '-' + (idNumber[2] + idNumber[3]) + '-' + (idNumber[4] + idNumber[5]); /* YYYY-MM-DD */
        } else if (year > 40 && year < 99) { /* this considers 1941 to 1999 */
          date += '19' + year.toString() + '-' + (idNumber[2] + idNumber[3]) + '-' + (idNumber[4] + idNumber[5]); /* YYYY-MM-DD */
        }

        return new Date(date);
      } else {
        return new Date();
      }
    }

    prepareCandidateModel(): void {
      this.candidateModel = new CandidateModel();

      this.candidateModel.email = this.email.value.toLowerCase();
      this.candidateModel.surname = this.lastName.value;
      this.candidateModel.name = this.firstName.value;
      this.candidateModel.gender = this.returnGenderFromIdNumber(this.idNumber.value);
      this.candidateModel.identityNumber = this.idNumber.value;
      this.candidateModel.countryOfBirth = 'South Africa';
      this.candidateModel.isActive = true;
      this.candidateModel.currentStage = '';

      this.candidateModel.location = new LocationModel();
      this.candidateModel.location.candidateId = this.candidateModel.id;
      this.candidateModel.location.lineone = '';
      this.candidateModel.location.linetwo = '';
      this.candidateModel.location.linethree = '';
      this.candidateModel.location.linefour = '';
      this.candidateModel.location.code = 20009;
    }

    prepareCandidateProfileModel(): void {
      this.candidateProfileModel = new CandidateProfileModel();

      this.candidateProfileModel.candidateId = this.candidateModel.id;
      this.candidateProfileModel.email = this.email.value.toLowerCase();
      this.candidateProfileModel.cellphoneNumber = 0;
      this.candidateProfileModel.userName = this.email.value.toLowerCase();
      this.candidateProfileModel.currentCity = 'Not Specified';
      this.candidateProfileModel.contactDetails = 0;
      this.candidateProfileModel.idNumber = +this.idNumber.value;
      this.candidateProfileModel.populationGroup = PopulationGroupEnum.Undefined;
      this.candidateProfileModel.firstName = this.firstName.value;
      this.candidateProfileModel.lastName = this.lastName.value;
      this.candidateProfileModel.gender = this.returnGenderFromIdNumber(this.idNumber.value);
      this.candidateProfileModel.isActive = true;
      this.candidateProfileModel.homeLanguage = LanguageEnum.Undefined;
      this.candidateProfileModel.dateOfBirth = this.returnCandidateDateOfBirth(this.idNumber.value);
      this.candidateProfileModel.disability = DisabilityEnum.Undefined;
      this.candidateProfileModel.isNewAccount = true;
    }

    prepareUserRegistrationModel(): void {
      this.user = new UserRegistrationModel();

      this.user.ClientId = this.authenticationService.ClientId;
      this.user.Email = this.email.value;
      this.user.LastName = this.lastName.value;
      this.user.FirstName = this.firstName.value;
      this.user.UseAD = this.useAD;
      this.user.Password = this.password.value;
      this.user.IsDashboard = false;
      this.user.ReturnUrl = this.authenticationService.ReturnUrl;
    }

    async checkIfCandidateExists(): Promise<boolean> {
      const filterCriteria = new FilterCriteria();
      filterCriteria.IdNumberKeys = new Array<string>();
      filterCriteria.IdNumberKeys.push(this.idNumber.value);
      filterCriteria.EmailKey = this.email.value;

      const observableCandites = this.candidatesService.GetByFilterCriteria(filterCriteria);
      const promise = await observableCandites.toPromise();

      if (promise.length > 0) {
        return true;
      }
      return false;
    }

    createCandidateProfile(candidate: CandidateProfileModel): void {
      this.candidatesprofileService.Upsert(candidate).subscribe(response => {
        this.success = true;
        console.log(response);
        this.router.navigate(['./verifyUser']);
      }, error => {
        console.log(error);
      });
    }

    createCandidateAccount(): void {
        this.candidatesService.Upsert(this.candidateModel).subscribe(response => {
          this.success = true;
          console.log(response);

          this.createCandidateProfile(this.candidateProfileModel);
        }, error => {
          console.log(error);
        });
    }

  async registerCandidate(): Promise<void> {
    this.markFormFieldsAsTouched();
    this.errorMessages = [];

    if (this.registerForm.valid) {
      /* Prep Models */
      this.prepareCandidateModel();
      this.prepareCandidateProfileModel();
      this.prepareUserRegistrationModel();

      if (await this.checkIfCandidateExists()) {
        this.submitted = true;
        this.errorMessages.push('Failed to complete Sign Up. ID Number is already in use');
        // this.formFields.password.setErrors({incorrect: true});
      } else {
        this.authenticationService.registration(this.user).subscribe(data => {
          if (typeof data === 'string' && data === 'Suceess') {
            localStorage.setItem('verify', 'register');

          } else {
            this.submitted = true;
            this.errorMessages.push('Failed to complete registration.');
          }

        }, error => {
          console.log(error);
          this.success = true;
          this.submitted = true;
          this.errorMessages.push('Failed to complete registration.');
        });
      }
      this.createCandidateAccount();
    }
  }

    markFormFieldsAsTouched(): void {
        this.firstName.markAsTouched();
        this.lastName.markAsTouched();
        this.email.markAsTouched();
        this.idNumber.markAsTouched();
        this.password.markAsTouched();
    }

    /* -RegisterForm Getters- */
    get firstName(): AbstractControl {
        return this.registerForm.get('firstName');
    }

    get lastName(): AbstractControl {
        return this.registerForm.get('lastName');
    }

    get email(): AbstractControl {
        return this.registerForm.get('email');
    }

    get idNumber(): AbstractControl {
        return this.registerForm.get('idNumber');
    }

    get password(): AbstractControl {
        return this.registerForm.get('password');
    }


    async register(): Promise<void> {

        this.markFormFieldsAsTouched();

        if (this.registerForm.valid) {
          this.errorMessages = [];
          this.user = new UserRegistrationModel();
          this.user.Email = this.email.value;
          this.user.LastName = this.lastName.value;
          this.user.FirstName = this.firstName.value;
          this.user.UseAD = this.useAD;
          this.user.Password = this.password.value;
          this.user.ClientId = this.authenticationService.ClientId;
          /*this.user.ConfirmPassword = this.confirmPassword.value;
          this.user.Username = this.username.value;*/
          this.user.IsDashboard = false;
          this.user.ReturnUrl = this.authenticationService.ReturnUrl;

          const filterCriteria = new FilterCriteria();
          filterCriteria.IdNumberKeys = new Array<string>();
          filterCriteria.IdNumberKeys.push(this.idNumber.value);
          filterCriteria.EmailKey = this.email.value;

          const observableCandites = this.candidatesService.GetByFilterCriteria(filterCriteria);
          const candidates = await observableCandites.toPromise();
          console.log(candidates);

          if (candidates.length > 0) {
            this.submitted = true;
            this.errorMessages.push('Failed to complete Sign Up. ID Number is already in use');
            // this.formFields.password.setErrors({incorrect: true});
          } else {
            this.authenticationService.registration(this.user).subscribe(data => {
              if (typeof data === 'string' && data === 'Suceess') {
                localStorage.setItem('verify', 'register');
                this.router.navigate(['./verifyUser']);

              } else {
                this.submitted = true;
                this.errorMessages.push('Failed to complete registration.');
              }

            }, error => {
              console.log(error);
              this.success = true;
              this.submitted = true;
              // console.log(response, this.submitted);
              // console.log('Failed to complete registration');
              this.errorMessages.push('Failed to complete registration.');
              // this.formFields.password.setErrors({incorrect: true});
            });
          }



            // const user = this.authenticationService.getUserCredential();
            // const candidateUser = new CandidateModel();
            // candidateUser.email = user.username;
            // candidateUser.identityNumber = this.idNumber.value;
            // const filterCriteria = new FilterCriteria();
            // filterCriteria.IdNumberKeys = new Array<string>();
            // filterCriteria.IdNumberKeys.push(candidateUser.identityNumber);
            // filterCriteria.EmailKey = user.username;
            // const observableCandites = this.candidatesService.GetByFilterCriteria(filterCriteria);
            // const x = await observableCandites.toPromise();
            // console.log(x);

        //     if (x.length === 0) {
        //
        //         candidateUser.email = this.email.value;
        //         candidateUser.name = this.user.FirstName;
        //         candidateUser.surname = this.user.LastName;
        //         candidateUser.gender = this.returnGenderFromIdNumber(this.idNumber.value);;
        //         candidateUser.countryOfBirth = 'South Africa';
        //         candidateUser.passportNumber = 0;
        //
        //         candidateUser.isActive = false;
        //         candidateUser.location = new LocationModel();
        //         candidateUser.location.candidateId = candidateUser.id;
        //         candidateUser.location.code = 20009;
        //         candidateUser.location.lineone = '';
        //         candidateUser.location.linetwo = '';
        //         candidateUser.location.linethree = '';
        //         candidateUser.location.linefour = '';
        //         candidateUser.currentStage = '';
        //         this.candidatesService.Upsert(candidateUser)
        //             .subscribe(o => {
        //                     console.log('Successfull');
        //                     const candidateProfileModel = new CandidateProfileModel();
        //                     candidateProfileModel.candidateId = candidateUser.id;
        //                     candidateProfileModel.email = candidateUser.email;
        //                     candidateProfileModel.cellphoneNumber = 0;
        //                     candidateProfileModel.userName = candidateUser.email;
        //                     candidateProfileModel.currentCity = 'Not Specified';
        //                     candidateProfileModel.contactDetails = 0;
        //                     candidateProfileModel.idNumber = +candidateUser.identityNumber;
        //                     candidateProfileModel.populationGroup = PopulationGroupEnum.Undefined;
        //                     candidateProfileModel.firstName = candidateUser.name;
        //                     candidateProfileModel.lastName = candidateUser.surname;
        //                     candidateProfileModel.gender = this.returnGenderFromIdNumber(this.idNumber.value);;
        //                     candidateProfileModel.isActive = true;
        //                     candidateProfileModel.homeLanguage = LanguageEnum.Undefined;
        //                     candidateProfileModel.dateOfBirth = this.returnCandidateDateOfBirth(this.idNumber.value);;
        //                     candidateProfileModel.disability = DisabilityEnum.Undefined;
        //                     candidateProfileModel.isNewAccount = true;
        //
        //                     this.candidatesprofileService.Upsert(candidateProfileModel)
        //                         .subscribe(profile => {
        //                             console.log('success profile');
        //                             this.authenticationService.registration(this.user).subscribe(data => {
        //                                 console.log(data);
        //                                 // setup for an email notification here
        //                                 localStorage.setItem('verify', 'register');
        //                                 this.router.navigate(['./verifyUser']);
        //
        //                             }, response => {
        //                                 console.log(response);
        //                                 this.success = true;
        //                                 this.submitted = true;
        //                                 // console.log(response, this.submitted);
        //                                 // console.log('Failed to complete registration');
        //                                 this.errorMessages.push('Failed to complete registration.');
        //                                 // this.formFields.password.setErrors({incorrect: true});
        //                             });
        //                         });
        //                 },
        //                 response => {
        //                     console.log('failed');
        //                 }
        //             );
        //
        //     } else {
        //         this.success = true;
        //         this.submitted = true;
        //         // console.log(response, this.submitted);
        //         // console.log('Failed to complete registration');
        //         this.errorMessages.push('Failed to complete registration. ID Number is already in use');
        //         // this.formFields.password.setErrors({incorrect: true});
        //     }
        }

    }
}



