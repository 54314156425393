import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ApplicationsHomeComponent} from './Components/applications/applications-home/applications-home.component';
import {ApplicationDetailsComponent} from './Components/applications/application-details/application-details.component';
import {LoginComponent} from './Components/accounts/login/login.component';
import {RegisterComponent} from './Components/accounts/register/register.component';
import {ForgetPasswordComponent} from './Components/accounts/forget-password/forget-password.component';
import {ProfileComponent} from './Components/common/navbar/profile/profile.component';
import {ProfileEditComponent} from './Components/common/navbar/profile/profile-edit/profile-edit.component';
import {LoadQuestionsComponent} from './Components/questions/load-questions/load-questions.component';
import {OnBoardingQuestionsComponent} from './Components/onBoarding/on-boarding-questions/on-boarding-questions.component';
import {RegisterSuccessComponent} from './Components/accounts/register-success/register-success.component';
import {VerifyUserComponent} from './Components/accounts/verify-user/verify-user.component';
import {ErrorComponent} from './Components/accounts/error/error.component';
import {ChangePasswordComponent} from './Components/accounts/change-password/change-password.component';
import {MyApplicationsComponent} from './Components/common/navbar/my-applications/my-applications.component';
import {RenderStagesAndQuestionsComponent} from './Components/questions/render-stages-and-questions/render-stages-and-questions.component';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {path: 'onBoarding', component: OnBoardingQuestionsComponent},
  {path: 'applications', component: ApplicationsHomeComponent},

  {path: 'application-details/:applicationId', component: ApplicationDetailsComponent},
  {path: 'error', component: ErrorComponent},
  {path: 'verifyUser', component: VerifyUserComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'registerSuccess', component: RegisterSuccessComponent},
  {path: 'login', component: LoginComponent},
  {path: 'forgotPassword', component: ForgetPasswordComponent},
  {path: 'changePassword/:email/:code', component: ChangePasswordComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'profile-edit/:id', component: ProfileEditComponent},
  {path: 'load-questions/:id', component: LoadQuestionsComponent, canActivate: [AuthGuard] },
  {path: 'candidate-questions/:id', component: RenderStagesAndQuestionsComponent, canActivate: [AuthGuard] },
  {path: '', redirectTo: 'applications', pathMatch: 'full'}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
