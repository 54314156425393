import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {CandidatesprofileService} from '../../../../Services/ProfilesService/CandidateService/candidatesprofile.service';
import {CandidateProfileModel} from '../../../../Core/Models/CandidateProfile.Model';
import {FilterCriteria} from '../../../../Core/Models/FilterCriteria';
import {AuthenticationService} from '../../../../Services/AuthenticationService/authentication.service';
import {CandidatesService} from '../../../../Services/CandidatesService/candidates.service';
import {CandidateModel} from '../../../../Core/Models/CandidateModelModell.Model';
import {Gender} from '../../../../Core/Models/Gender.Model';
import {Title} from '@angular/platform-browser';
import {LanguageEnum} from '../../../../Core/Enums/Language.Enum';
import {PopulationGroupEnum} from '../../../../Core/Enums/PopulationGroup.Enum';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  title = 'Profile';
  candidate: CandidateProfileModel;
  cand: CandidateModel;
  private id;
  public filterCriteria: FilterCriteria;

  constructor(public router: Router,
              private profilesService: CandidatesprofileService,
              private authenticationService: AuthenticationService,
              private candidateService: CandidatesService,
              private titleService: Title
        ) {}

  ngOnInit(): void {
    const userDetails = this.authenticationService.getUserCredential();
    console.log(userDetails);
    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.EmailKey = userDetails.username;
    this.getCandidate();
    this.titleService.setTitle(this.title);
  }

  getCandidate(): void {
    this.candidateService.GetByFilterCriteria(this.filterCriteria).subscribe(result => {
      this.cand = new CandidateModel();
      this.cand = result[0];
      this.getProfile(this.cand.id);
    });
  }

  getProfile(id: string): void {
    this.filterCriteria.candidateIdKeys = new Array<string>();
    this.filterCriteria.candidateIdKeys = [id.toLowerCase()];
    this.profilesService.GetByFilterCriteria(this.filterCriteria).subscribe(result => {
        this.candidate = result[0];
      });
  }

  goToEditProfile(id): void {
    this.router.navigate(['profile-edit', id]);
  }

  sanitizeGender(gender: Gender): string {
    return Gender[gender];
  }

  sanitizeHomeLanguage(language: LanguageEnum): string {
    return LanguageEnum[language];
  }

  sanitizePopulationGroup(populationGroup: PopulationGroupEnum): string {
    return PopulationGroupEnum[populationGroup];
  }

  sanitizeContactNumber(contactNumber: any): string {
    if (contactNumber.toString().length === 1) {
      return '0';
    } else {
      return '0' + contactNumber.toString();
    }
  }

  getCorrectProfileIcon(candidate: CandidateProfileModel): string {
    if (candidate.gender === Gender.Male) {
      return 'assets/icons/purple/man.svg';
    } else if (candidate.gender === Gender.Female) {
      return 'assets/icons/purple/woman.svg';
    } else {
      return 'assets/icons/purple/user-2.svg';
    }
  }

}
