<div class="container background">
  <div class="left-box">
    <div class="company-logo">
      <a routerLink="/login">
        <img  class="logo" src="../../../../assets/logos/Hazie-logo-white.png" alt="Card image cap">
      </a>
    </div>
  </div>

  <div class="right-box">
    <div class="card">
      <div class="card-header">
        <div class="company-logo">
          <a routerLink="/login">
            <img  class="logo" src="../../../../assets/logos/Hazie-logo.png" alt="Card image cap">
          </a>
        </div>

        <div class="header-text">
          <h1>{{heading}}</h1>
          <small>{{message}}</small>
        </div>
      </div>

      <!--      <div class="card-body">-->
      <!--        <div class="form-container">-->
      <!--          <form>-->
      <!--            <input type="submit" routerLink="/login" value="Take me home">-->
      <!--          </form>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="card-footer">
      </div>
    </div>
  </div>
</div>
