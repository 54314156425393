import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  title = 'HAZIE - Forgot password';
  error = false;

  constructor(private router: Router, private authenticationService: AuthenticationService,
              private route: Router,
              private formBuilder: FormBuilder,
              private titleService: Title) {}

  ngOnInit(): void {
    this.initiateForm();
    this.titleService.setTitle(this.title);
  }

  initiateForm(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['',
        [Validators.required,
          Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]]
    });
  }

  /* -Form Getters- */
  get email(): AbstractControl {
    return this.forgotPasswordForm.get('email');
  }

  sendRecoveryEmail(): void {
    /* -Mark Form Field as touched- */
    this.email.markAsTouched();

    if (this.forgotPasswordForm.valid) {
      this.authenticationService.sendRecoveryEmail(this.email.value)
        .subscribe(
          data => {
            if (data === 'Error') {
              this.error = true;
            } else {
              console.log(data);
              console.log('Password reset email send successfully');
              this.authenticationService.logout();
              localStorage.setItem('verify', 'reset');
              this.router.navigate(['./verifyUser']);
            }
          }, response => {
            console.log(response);
            console.log('Password reset email sending unsuccessful');
            this.router.navigate(['./error']);
          }
        );
    }

  }


}
