<div class="container background">
  <div class="left-box">
    <div class="company-logo">
      <a routerLink="/login">
        <img  class="logo" src="../../../../assets/logos/Hazie-logo-white.png" alt="Card image cap">
      </a>
    </div>
  </div>

  <div class="right-box">
    <div class="card">
      <div class="card-header">
        <div class="company-logo">
          <a routerLink="/login">
            <img  class="logo" src="../../../../assets/logos/Hazie-logo.png" alt="Card image cap">
          </a>
        </div>

        <div class="header-text">
          <h1>CHANGE PASSWORD</h1>
          <small>ENTER NEW PASSWORD</small>
        </div>
      </div>

      <div class="card-body">
        <div class="form-container">
          <form [formGroup]="ResetPasswordForm" (ngSubmit)="changePassword()">
            <input
              placeholder="NEW PASSWORD"
              type="password"
              id="passwordConfirmed" formControlName="newPassword"/>

            <input type="submit" value="Change Password">
          </form>
        </div>
      </div>

      <div class="card-footer">
        <div class="errors">
          <div class="error-messages" *ngIf="newPassword.touched">
            <div *ngIf="newPassword.invalid && newPassword.dirty">* New Password needs to be at least 6 characters </div>
            <div *ngIf="!newPassword.value">* New Password can't be blank</div>
          </div>

          <div class="error-messages">
            <div *ngIf="submitted">{{errorMessages}}</div>
          </div>
        </div>

        <div class="forgot-password">
          <div class="sign-up-login">
            Have an account? <a routerLink="/login" class="color-alert">Sign In</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
