import {Component, Input, OnInit} from '@angular/core';
import {ApplicationModel} from '../../../../Core/Models/Application.Model';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss']
})
export class ApplicationCardComponent implements OnInit {

  @Input() application: ApplicationModel;
  @Input() currentId;
  @Input() Auth;
  currentDate: Date;



  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.currentDate = new Date();

  }


  goToApplicationDetails(application: ApplicationModel): void {
    /* Hardcode OldMutual Redirect */
    if (application.companyId === '1f5f7eb5-734f-b345-ca11-5f75b9286328') {
      window.open('https://oldmutual.hazie.co.za/application-details/' + application.id, '_blank');
    } else {
      this.router.navigate(['application-details', application.id]);
    }
  }

  goToApplicationQuestions(application: ApplicationModel): void {

    /* Hardcode OldMutual Redirect */
    if (application.companyId === '1f5f7eb5-734f-b345-ca11-5f75b9286328') {
      window.open('https://oldmutual.hazie.co.za', '_blank');
    } else {
      this.router.navigate(['candidate-questions', this.currentId]);
    }
  }

  applicationClosed(x: ApplicationModel): boolean  {
    const date = new Date(x.expiryDate);
    if (moment(date).isBefore(this.currentDate)) {
      return false;
    } else if (moment(date).isSame(this.currentDate)) {
      return false;
    } else if (moment(new Date(date)).isAfter(this.currentDate)) {
      return  true;
    }
  }
}
