<app-navbar></app-navbar>

<div class="main-container" >

  <nav>
    <ul>
      <button type="button" class="button nav-button" class="{{getActive('all')}}" (click)="setActive('all')"><li>All</li></button>
      <button type="button" class="button nav-button" class="{{getActive('Bursaries')}}"  (click)="setActive('Bursaries')"><li>Bursaries</li></button>
      <button type="button" class="button nav-button" class="{{getActive('Graduates')}}"  (click)="setActive('Graduates')"><li>Graduates</li></button>
      <button type="button" class="button nav-button" class="{{getActive('Learnerships')}}"  (click)="setActive('Learnerships')"><li>Learnerships</li></button>
      <button type="button" class="button nav-button" class="{{getActive('Internships')}}"  (click)="setActive('Internships')"><li>Internships</li></button>
      <button type="button" class="button nav-button" class="{{getActive('Apprenticeships')}}"  (click)="setActive('Apprenticeships')"><li>Apprenticeships</li></button>
    </ul>
  </nav>

  <div class="cards" *ngIf="filteredApps.length > 0">
    <app-application-card *ngFor="let application of filteredApps" [application]="application" [currentId]="application.id" [Auth]="authentication"></app-application-card>
  </div>
  <div class="not-found-message" *ngIf="filteredApps.length === 0">
    <div *ngIf="choice === 'all'">No applications found!</div>
    <div *ngIf="choice !== 'all'">We currently do not have {{choice}} opportunities. </div>
  </div>
</div>

<footer *ngIf="filteredApps.length > 0">
  <div class="hazie-logo">
    <a href="https://info.hazie.co.za" target="_blank" style="text-decoration: none;">
      <img height="50" width="200" src="../../../../assets/logos/powered-by-hazie-color-transparent.png" alt="">
    </a>
  </div>
</footer>
