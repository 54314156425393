<div class="container background">
  <div class="left-box">
    <div class="company-logo">
      <a routerLink="/applications">
        <img  class="logo" src="../../../../assets/logos/Hazie-logo-white.png" alt="Card image cap">
      </a>
    </div>
  </div>

  <div class="right-box">
    <div class="card">
      <div class="stage-header">
        {{currentStage.name}}
      </div>

      <div class="back-button">
        <img *ngIf="(questionIndex > 0 || stageIndex > 0) && !showOkButton && !endOfQuestions" (click)="goToPreviousQuestion()" class="back-arrow-button" src="../../../../assets/images/arrow.svg" >
        <div *ngIf="(questionIndex > 0 || stageIndex > 0) && !showOkButton && !endOfQuestions" (click)="goToPreviousQuestion()" class="back-text"> BACK</div>
      </div>

      <div class="inner-card">
        <div class="progress-bar">
          <div class="bar">
            <span class="fill" style="{{progressStyling}}"></span>
          </div>
        </div>

        <div class="message-bubble">
          <div class="bubble" *ngIf="bubbleMessage">
            <h5>
              <div class="error-messages" *ngIf="!skipQuestion">* Question is not skippable!</div>
              <div class="success-messages" *ngIf="documentUploaded">* Document Already uploaded</div>
              <a href="{{bubbleMessage}}" *ngIf="isOdysseyQuestion && !endOfQuestions">Odyssey Assessment</a>
              <div *ngIf="isOdysseyQuestion && endOfQuestions"
                   [innerHTML]="dom.bypassSecurityTrustHtml(this.bubbleMessage)"></div>
              <div *ngIf="!isOdysseyQuestion"
                   [innerHTML]="dom.bypassSecurityTrustHtml(this.bubbleMessage)">
              </div>
            </h5>
          </div>
          <div class="bubble" *ngIf="!bubbleMessage">
            <img src="../../../../assets/logo/loading-gears.gif" alt="loading animation">
          </div>
        </div>

        <div class="hazie-logo">
          <a routerLink="/applications">
            <img src="../../../../assets/logo/icon.png" alt="Card image cap">
          </a>
        </div>

        <div class="question-input-container">
          <div class="question-input" *ngIf="!endOfQuestions && currentQuestion">
            <app-input-free-text
              (answer)="collectInputValue($event)"
              [question]="currentQuestion"
              [candidateId]="candidateId"
              *ngIf="currentQuestion.questionType === 0">
            </app-input-free-text>
            <app-input-upload
              (answer)="collectInputValue($event)"
              (file)="collectUploadValue($event)"
              (purpose)="collectFilePurpose($event)"
              (documentModel)="collectDocumentModel($event)"
              (childQuestion)="collectCurrentInputQuestion($event)"
              (documentUploaded)="collectDocumentUploadedStatus($event)"
              [question]="currentQuestion"
              [candidateId]="candidateId"
              *ngIf="currentQuestion.questionType === 1">
            </app-input-upload>
            <app-input-select
              (answer)="collectInputValue($event)"
              [question]="currentQuestion"
              [candidateId]="candidateId"
              *ngIf="currentQuestion.questionType === 2">
            </app-input-select>
            <app-input-multi-select
              (answer)="collectInputValue($event)"
              [question]="currentQuestion"
              [candidateId]="candidateId"
              *ngIf="currentQuestion.questionType === 3" style="width: 100%">
            </app-input-multi-select>
          </div>
        </div>

        <div class="action-button" *ngIf="bubbleMessage">
          <button
            *ngIf="!endOfQuestions && !showOkButton && !endOfCurrentStage"
            type="submit"
            class="button next-button"
            (click)="goToNextQuestion()">Next</button>
          <div class="error-messages add-hover">
            <a
              *ngIf="!endOfQuestions && !showOkButton && isStageSkippable"
              (click)="goToNextQuestion()">skip question?</a>
          </div>
          <button
            *ngIf="endOfQuestions && !showOkButton && endOfCurrentStage && !endOfStages"
            type="submit"
            class="button next-button"
            (click)="goToNextStage()">Next Stage</button>
          <button
            *ngIf="showOkButton"
            type="submit"
            class="button next-button"
            (click)="completeApplicationAndGoHome()">OK</button>
          <button
            *ngIf="endOfStages"
            type="submit"
            class="button next-button"
            (click)="completeApplicationAndGoHome()">Complete</button>
        </div>
        <!--        {{answer}}-->
      </div>

    </div>
  </div>
</div>
